import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

// Define the initial state
const initialState = {
    insurances: [],
    loading: false,
    error: null,
};

export const addInsurance = createAsyncThunk(
    'insurance/addInsurance',
    async ({ data,  month, year, company_id  }, { rejectWithValue }) => {
        try {
            const response = await api.post('/insurance/insurance', { data, month, year, company_id });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const getAllInsurances = createAsyncThunk(
    'insurance/getAllInsurances',
    async ({ company_id }, { rejectWithValue }) => {
        try {
            const response = await api.post('/insurance/insurance/all', { company_id });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const deleteInsurance = createAsyncThunk(
    'insurance/deleteInsurance',
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/insurance/insurance/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);



// Define the slice
const insuranceSlice = createSlice({
    name: 'insurances',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addInsurance.pending, (state) => {
                state.loading = true;
            })
            .addCase(addInsurance.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addInsurance.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getAllInsurances.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllInsurances.fulfilled, (state, action) => {
                //order by month 
                // action.payload.sort((a, b) => a.month - b.month);
                state.insurances = action.payload;
                state.loading = false;
            })
            .addCase(getAllInsurances.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteInsurance.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteInsurance.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteInsurance.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
    },
});

// Export actions
export default insuranceSlice.reducer;
